<template>
  <div class="security">
    <div class="security__header">
      <div class="security__header-title">
        <h3>Security</h3>
      </div>
    </div>
    <div class="security__top-block">
      <h4 class="security__top-block-title">Change password</h4>
      <div class="security__top-block-password">
        <div class="password__block">
          <LabelField id="password-old" title="Old password" />
          <TextField
            id="password-old"
            v-model.trim="oldPassword"
            name="password"
            type="password"
            is-password
            :password-strength="oldPasswordStrength"
            :error="isOldPasswordError"
            :error-txt="oldPasswordErrorText"
            tooltip-text="Password must be at least 8 characters with 1 upper case letter and 1 number."
            @focus="resetServerError('current_password')"
          />
        </div>
        <div class="password__block">
          <LabelField id="new-password" title="New password" />
          <TextField
            id="new-password"
            v-model.trim="password"
            name="password"
            type="password"
            is-password
            :password-strength="passwordStrength"
            :error="isPasswordError"
            :error-txt="passwordErrorText"
            tooltip-text="Password must be at least 8 characters with 1 upper case letter and 1 number."
            @focus="resetServerError('new_password')"
          />
        </div>
        <div class="password__block">
          <LabelField id="confirm-password" title="Password confirmation" />
          <TextField
            id="confirm-password"
            v-model.trim="confirmPassword"
            name="password"
            type="password"
            is-password
            :password-strength="confirmPasswordStrength"
            :error="isConfirmPasswordError"
            :error-txt="confirmPasswordErrorText"
            tooltip-text="Password must be at least 8 characters with 1 upper case letter and 1 number."
            @focus="resetServerError('new_password_confirmation')"
          />
        </div>
      </div>
      <div class="security__top-block-button">
        <ButtonBase :loading="confirmLoader" @click="saveNewPassword"
          >Save</ButtonBase
        >
      </div>
    </div>
    <div class="security__bottom-block">
      <div class="security__bottom-block-title">
        <h4>Connect 2FA</h4>
        <div v-if="!getProfile.two_factor_enabled" class="unset__block">
          <svg-icon name="unset_button"></svg-icon>
          <p>Unset</p>
        </div>
        <div v-else class="set__block">
          <svg-icon name="set_2fa"></svg-icon>
          <p>On</p>
        </div>
      </div>
      <h5 class="subtitle download__title">
        Download App Google Authenticator
      </h5>
      <div class="download__blocks">
        <a
          class="download__blocks-google"
          href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
          target="_blank"
        >
          <svg-icon class="google__icon" name="google"></svg-icon>
          <h5 class="download__blocks-google-subtitle subtitle">
            Download from
          </h5>
          <h4 class="download__blocks-google-title">Google Play</h4>
        </a>
        <a
          class="download__blocks-apple"
          href="https://apps.apple.com/ru/app/google-authenticator/id388497605"
          target="_blank"
        >
          <svg-icon class="apple__icon" name="apple"></svg-icon>
          <h5 class="download__blocks-apple-subtitle subtitle">
            Download from
          </h5>
          <h4 class="download__blocks-apple-title">App Store</h4>
        </a>
      </div>
      <div class="enable-2fa__button">
        <ButtonBase
          v-if="!getProfile.two_factor_enabled"
          :loading="enable2FALoader"
          @click="enable2FA()"
          >Enable 2FA</ButtonBase
        >
        <ButtonBase v-else :loading="disable2FALoader" @click="disable2FA()"
          >Disable 2FA</ButtonBase
        >
      </div>
    </div>
  </div>
</template>

<script>
import securityChangePasswordValidation from '@/mixins/validation/forms/securityChangePassword.js';
import LabelField from '@/elements/LabelField';
import { mapGetters } from 'vuex';

export default {
  components: { LabelField },
  mixins: [securityChangePasswordValidation],
  data() {
    return {
      enable2FALoader: false,
      disable2FALoader: false,
      oldPassword: '',
      password: '',
      confirmPassword: '',
      confirmLoader: false,
      referalID: 'Gh22J8',
      referalLink: 'https://vyron.io/referral',
    };
  },
  computed: {
    ...mapGetters(['getProfile', 'getErrors', 'getDashboardSettings']),
  },
  async created() {
    await this.$store.dispatch('getProfile');
    await this.$store.dispatch('getDashboardSettings');
  },
  methods: {
    async copyURL(text) {
      try {
        await navigator.clipboard.writeText(text);
        this.$store.dispatch('setNotification', {
          type: 'success',
          title: `Copied to clipboard`,
          text: ``,
        });
      } catch ($e) {
        alert('Cannot copy');
      }
    },
    resetPasswords() {
      this.oldPassword = '';
      this.password = '';
      this.confirmPassword = '';
    },
    async saveNewPassword() {
      if (this.isInvalidForm) {
        this.showValidationErrors();
        return;
      }
      this.confirmLoader = true;
      const res = await this.$store.dispatch('changePassword', {
        current_password: this.oldPassword,
        new_password: this.password,
        new_password_confirmation: this.confirmPassword,
      });
      this.confirmLoader = false;

      if (res) {
        this.hideValidationErrors();
        this.resetPasswords();
      }
    },
    async enable2FA() {
      this.enable2FALoader = true;
      await this.$store.dispatch('getTwoFactorSecret');
      this.enable2FALoader = false;
      this.$store.commit('modal/SET_MODAL', {
        state: true,
        name: 'two-factor',
      });
    },
    async disable2FA() {
      this.disable2FALoader = true;
      await this.$store.dispatch('sendEmailVerificationCode');
      this.disable2FALoader = false;
      this.$store.commit('modal/SET_MODAL', {
        state: true,
        name: 'two-factor-disable',
      });
    },
  },
};
</script>

<style lang="sass" scoped>
.security
  padding: 2.4rem
  display: flex
  flex-direction: column
  gap: 2.4rem
  flex: 0 0 100%

.security__header
  display: flex
  justify-content: space-between
  align-items: center
  // @include m
  //   flex-direction: column

.security__header-buttons
  display: flex
  justify-content: space-between
  gap: 2.4rem

.security__header-title
  display: flex
  justify-content: space-between

.security__top-block, .security__center-block, .security__bottom-block
  display: flex
  background-color: $white
  flex-direction: column
  width: 100%
  gap: 2.4rem
  padding: 2.4rem
  &-button
    margin-left: auto

.security__top-block-password
  display: flex
  justify-content: space-between
  gap: 2.4rem
  @include l
    flex-direction: column
    max-width: 44rem
    margin: 0 auto
    width: 100%
    // justify-content: center
    // align-items: center


.password__block
  min-width: 20rem
  flex-grow: 1
  // flex: 0 0 30%

.input__blocks
  display: flex
  gap: 2.4rem
  flex-direction: row
  justify-content: space-between
  flex-wrap: wrap

.security__center-block-title
  display: flex
  align-items: end
  gap: 1.2rem

.referal-id__block, .referal-link__block
  display: flex
  flex-direction: column
  gap: 0.8rem
  flex-grow: 1

.referal-id__header, .referal-link__header
  display: flex
  justify-content: space-between
  align-items: center
  & svg
    height: 1.4rem

.security__bottom-block-title
  display: flex
  align-items: center
  gap: 1.2rem

.unset__block, .set__block
  display: flex
  align-items: center
  & svg
    height: 1.4rem
    width: 2.8rem

.unset__block
  color: $negative-text

.set__block
  color: $success-text

.download__title
  margin: 0 auto

.download__blocks
  display: flex
  gap: 2.4rem
  justify-content: space-between
  @include m
    flex-wrap: wrap

.download__blocks-google, .download__blocks-apple
  background-color: $background-color
  text-align: center
  padding: 4rem
  flex: 0 1 100%
  cursor: pointer

.google__icon, .apple__icon
  margin-bottom: 2.4rem
  max-height: 5rem
  max-width: 5rem

.download__blocks-google-subtitle, .download__blocks-apple-subtitle
  margin-bottom: 0.8rem

.enable-2fa__button
  margin-left: auto

.info-circle
  cursor: pointer

.percentage-card__block
  display: flex
  gap: 2.4rem
  flex-wrap: wrap
  padding-top: 2.7rem
  @include l
    flex: 1 0 100%
    padding-top: 0


.percentage-card__item
  display: flex
  flex-grow: 1
  padding: 1.3rem 1.8rem
  background-color: $background-color
  justify-content: start
  align-items: center
  white-space: nowrap
  @include m
    width: 100%

  & svg
    max-height: 1.2rem
    max-width: 0.8rem
    margin-right: 0.5rem
</style>
